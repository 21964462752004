// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // Hotspot open
  $(document).on('click', '.js-hotspot', function (e) {
    let windowWidth = $(window).width();
    e.preventDefault();
    if ($(this).hasClass('active')) {
      $('.hotspot').removeClass('is-active');
      $('.js-hotspot').removeClass('active');
      $('.hotspot__content').removeClass('is-active');
    } else {
      let dataId = $(this).attr('data-id');
      let content = $(this)
        .parent()
        .parent()
        .find('#' + dataId);
      let positionLeft = $(this).parent().position().left;
      let contentWidth = content.outerWidth() + 60;
      let sum = positionLeft + contentWidth;

      $('.hotspot__content').hide();
      $('.hotspot__content').removeClass('right');
      $('.hotspot__content').removeClass('is-active');
      $('.hotspot').removeClass('is-active');
      $('.js-hotspot').removeClass('active');
      $(this).addClass('active');
      $(this).closest('.hotspot').addClass('is-active');
      content.addClass('is-active');
      if (sum > windowWidth) {
        content.addClass('right');
      }
    }
  });

  $(document).on('click', '.js-hotspot-close', function (e) {
    e.preventDefault();
    $('.hotspot').removeClass('is-active');
    $('.js-hotspot').removeClass('active');
    $(this).parent().removeClass('is-active');
  });

  //realisation sliders
  $('.js-realisation-top').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: '.js-realisation-sync',
    focusOnSelect: true,
    infinite: false,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
  $('.js-realisation-sync').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.js-realisation-top',
    fade: true,
    infinite: false,
    arrows: false,
    rows: 0,
    adaptiveHeight: true,
    // dots: true,
    // centerMode: true,
  });

  //home logos slider
  $('.js-home-logos').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    focusOnSelect: true,
    infinite: false,
    autoplaySpeed: 5000,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
  //home featured slider
  $('.js-home-featured').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    // focusOnSelect: true,
    infinite: false,
    autoplaySpeed: 5000,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  //home trust slider
  $('.js-home-trust').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    arrows: false,
    dots: true,
    infinite: false,
    centerMode: true,
    centerPadding: '23%',
    autoplaySpeed: 5000,
    rows: 0,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          centerPadding: '15%',
        },
      },
      {
        breakpoint: 420,
        settings: {
          centerPadding: '10%',
        },
      },
    ],
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  resizeVideo();

  //expertise tabs
  let autoSwitchingEnabled = true;

  function autoSwitchTab() {
    if (!autoSwitchingEnabled) return;
    let activeTab = $('.circle-tabs .tab.active');
    let nextTab = activeTab.next('.tab');

    if (nextTab.length === 0) {
      nextTab = $('.circle-tabs .tab').first();
    }

    nextTab.addClass('auto-clicked').trigger('click');
  }
  let tabSwitchInterval;
  if ($('.circle-tabs .tab').length !== 0) {
    tabSwitchInterval = setInterval(autoSwitchTab, 5000);
  }
  function disableAutoSwitching() {
    clearInterval(tabSwitchInterval);
    autoSwitchingEnabled = false;
  }

  const $tabs = $('.tab');
  const $wrapper = $('.circle-tabs');
  const $images = $('.main-image');
  const $mainImage = $('.image-container');
  const $content = $('.home-expertise__tabs-text');
  $tabs.each(function (index) {
    $(this).on('click', function () {
      const newImage = $images[index];
      const newContent = $content[index];

      // Update active tab class
      $tabs.removeClass('active');
      $(this).addClass('active');

      $wrapper.attr('data-tab', index + 1);

      // Reset any ongoing transitions
      $mainImage.removeClass('fade-in fade-out');
      $content.removeClass('active');

      // Fade-out the current image
      $mainImage.addClass('fade-out');
      $(newContent).addClass('active');

      if (!$(this).hasClass('auto-clicked')) {
        console.log('test');
        disableAutoSwitching();
      }
      $(this).removeClass('auto-clicked');

      $mainImage.one('transitionend', function fadeOutComplete() {
        // Change the image source after fade-out
        // $mainImage.attr('src', newImage);
        $images.hide();
        $(newImage).show();
        $mainImage.removeClass('fade-out').addClass('fade-in');

        // Remove the fade-in class after the transition completes
        $mainImage.one('transitionend', function fadeInComplete() {
          $mainImage.removeClass('fade-in');
        });
      });
    });
  });

  //home tabs
  // const $homeTabs = $('.js-home-tabs');
  const $homeTab = $('.js-home-tab');
  // const $homeTabContents = $('.js-home-tab-contents');
  const $homeTabContent = $('.js-home-tab-content');
  $homeTab.each(function (index) {
    $(this).on('click', function () {
      // const newTab = $homeTab[index];
      const newTabContent = $homeTabContent[index];
      $homeTab.removeClass('active');
      $homeTabContent.removeClass('active');
      $(this).addClass('active');
      $(newTabContent).addClass('active');
    });
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
